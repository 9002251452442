import { useState, useEffect } from "react";
import { Navbar } from "./components/Navbar";
import { Loadscreen } from "./components/Loadscreen";
import Toparea from "./components/Toparea";

function App() {


  const [isMobile, setIsMobile] = useState(false);

  // Handle window resize
  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);

    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, [])

  return (
    <div className="App">

      <>
        <Navbar />
        <Toparea isMobile={isMobile} />

      </>






    </div>
  );
}

export default App;
