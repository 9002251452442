import React from 'react'
import pingstar from '../assets/imgs/stars/pingstar.png'
import logo from '../assets/imgs/logo.png'
import cloud1 from '../assets/imgs/cloud/cld1.png'
import cloud2 from '../assets/imgs/cloud/cld2.png'
import cloud3 from '../assets/imgs/cloud/cld3.png'

import { motion } from 'framer-motion';


export const Navbar = () => {

    const duration = Math.random() * 5 + 5; // Random duration between 5 and 10 seconds
    const distance = Math.random() * 30 + 20; // Random distance between 20 and 50px

    const cloudVariants = {
        animate: {
            x: [0, distance, 0, -distance, 0], // Move left and right around the initial position
            transition: {
                duration: duration,
                ease: "easeInOut",
                repeat: Infinity, // Repeat the animation infinitely
            },
        },
        whileHover: {
            x: 0, // Stop moving when hovered
            transition: { duration: 0.5 }, // Smooth transition to stop
        },
    };



    return (
        <div className='navbar'>

            <div className="left">
                <div className="logo-cnt">
                    <img src={logo} alt="logo" className='logo' />
                    <div className="spinner-wrapper">

                        <motion.img src={pingstar} alt="pingstar" className='pingstar' animate={{ rotate: 360 }}
                            transition={{
                                duration: 7, // Adjust the duration of one spin cycle (in seconds)
                                ease: "linear", // Use a linear animation curve for a smooth, consistent spin
                                repeat: Infinity, // Repeat the animation infinitely
                            }}
                        />

                    </div>

                </div>
            </div>

            <div className="right">
                <div className="clouds-cnt">
                    <a href="https://twitter.com/SporkOnSol">
                        <img src={cloud1} alt="cloud1" className='cloud' />
                    </a>
                    <a href="https://t.me/spork_portal">
                        <img src={cloud2} alt="cloud1" className='cloud' />
                    </a>
                    <a href="https://dexscreener.com/solana/7xePKci69KtBedCYGPYa4XYWT8KpjX9JmstvDB9BE73P">
                        <img src={cloud3} alt="cloud1" className='cloud' />
                    </a>
                </div>
            </div>
        </div>
    )
}
