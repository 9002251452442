import React, { useEffect, useState } from 'react';
import spork from '../assets/imgs/spork.png'
import hand from '../assets/imgs/hand2.png'
import cha1 from '../assets/imgs/chars/Pepe_wif_Spork.PNG'
import cha2 from '../assets/imgs/chars/Bonk_wif_Spork.PNG'
import cha3 from '../assets/imgs/chars/IMG_1553.PNG'
import cha4 from '../assets/imgs/chars/Myro_wif_Spork.PNG'

import lspoon from '../assets/imgs/blusporkpng.png'
import rspoon from '../assets/imgs/pinksporkpng.png'
import spoongroup from '../assets/imgs/spoons/family.png'

import lefthand from '../assets/imgs/hand1.png'
import righthand from '../assets/imgs/hand2.png'
import abtspork from '../assets/imgs/abtspork.png'

import circs from '../assets/imgs/texture/circs.png'
import blackstar from '../assets/imgs/stars/blackstar.png'
import mtune from '../assets/imgs/tunes/mtune.svg'

import photocollection from '../assets/imgs/photocollection.png'
import spooncollection from '../assets/imgs/spooncollection.png'



import wing from '../assets/imgs/wing.png'
import apple from '../assets/imgs/apple.png'
import moon from '../assets/imgs/tunes/circmoon.png'
import branch from '../assets/imgs/branch.png'
import leaf from '../assets/imgs/leaf.png'
import wave1 from '../assets/imgs/waves/wave1.png'
import wave2 from '../assets/imgs/waves/wave2.png'
import wave3 from '../assets/imgs/waves/wave3.png'

import waves from '../assets/imgs/waves/waves.png'


import endtitle from '../assets/imgs/endtitle.png'
import endtitle2 from '../assets/imgs/endtitle2.png'


import endtitlem from '../assets/imgs/endtitle-mob.png'
import endtitle2m from '../assets/imgs/endtitle2-mob.png'

import footer from '../assets/imgs/waves/footer.png'



import logo from '../assets/imgs/logo.png'
import cloud1 from '../assets/imgs/cloud/cld1.png'
import cloud2 from '../assets/imgs/cloud/cld2.png'
import cloud3 from '../assets/imgs/cloud/cld3.png'


import { motion, useAnimation } from 'framer-motion';


const Toparea = ({ isMobile }) => {


    const leftVariants = {
        animate: {
            y: ["0%", "25%", "0%"], // Move up and down
            transition: {
                duration: 2, // Duration of each cycle
                ease: "easeInOut", // Tweeny animation
                repeat: Infinity, // Loop infinitely
                repeatType: "loop", // Ensure it loops in a standard fashion
                repeatDelay: 1 // Delay before repeating the animation
            }
        }
    };



    const leftVariants2 = {
        animate: {
            y: ["0%", "25%", "0%"], // Move up and down
            transition: {
                duration: 1.8, // Duration of each cycle
                ease: "easeInOut", // Tweeny animation
                repeat: Infinity, // Loop infinitely
                repeatType: "loop", // Ensure it loops in a standard fashion
                repeatDelay: 0.2 // Delay before repeating the animation
            }
        }
    };


    // Animation variants for the right characters
    const rightVariants = {
        animate: {
            y: ["0%", "90%", "0%"],
            transition: {
                duration: 0.3, // Slightly different duration for variation
                ease: "easeInOut",
                repeat: Infinity,
                repeatType: "loop",
                repeatDelay: 2 // Different delay for asynchronous movement
            }
        }
    };


    const rightVariant2 = {
        animate: {
            y: ["0%", "15%", "0%"],
            transition: {
                duration: 1, // Slightly different duration for variation
                ease: "easeInOut",
                repeat: Infinity,
                repeatType: "loop",
                repeatDelay: 0.1 // Different delay for asynchronous movement
            }
        }
    };





    const textVariants = {
        initial: {
            x: "-40%",
        },
        animate: {
            x: '40%',
            transition: {
                duration: 60,
                ease: 'linear',
                repeat: Infinity,
                repeatType: 'loop',
            },
        },
    };


    const upTextVariants = {
        initial: {
            y: "-60%",
        },
        animate: {
            y: '0%',
            transition: {
                duration: 150,
                ease: 'linear',
                repeat: Infinity,
                repeatType: 'loop',
            },
        },
    };


    const sliderVariants = {
        animate: {
            x: [0, -20000], // Change '500' to the width of your container or desired distance
            transition: {
                duration: 120, // Duration of one cycle of the animation
                ease: "linear", // For a constant animation speed
                repeat: Infinity, // To loop the animation forever
                repeatType: "loop", // Ensure the animation loops back to the start
            },
        },
    };






    const wingAnimation = {
        rotate: [0, 10, -10, 0], // Rotate a few degrees back and forth
        transition: {
            duration: 2, // Duration of one cycle of the animation
            ease: "easeInOut", // For a smooth animation
            repeat: Infinity, // To loop the animation forever
            repeatType: "loop", // Ensure the animation loops smoothly
        },
    };



    // Animation for moving the spoon-cnt div from left to right
    const containerAnimation = {
        // Define keyframes for the x-axis movement (right, then left)
        x: [0, 1100, 1100, 0, 0],
        // Define keyframes for the y-axis movement (up, then down)
        y: ["0%", "0%", "-50%", "-50%", "0%"],
        // Define keyframes for rotation (rotate, then rotate back)
        rotate: [0, 0, -90, -180, 720],
        transition: {
            duration: 10, // Total duration of the animation sequence
            ease: "linear", // Use a linear ease for consistent pacing
            times: [0, 0.25, 0.5, 0.75, 1], // Timing for each keyframe (start, 25%, 50%, 75%, end)
            repeat: Infinity, // Repeat the animation indefinitely
        },
    };




    const bounceVariants = {
        animate: {
            scale: [1, 0.88, 1],
            transition: {
                duration: 2,
                ease: "easeInOut",
                repeat: Infinity,
                repeatType: "mirror",
            },
        },
    };


    const leafAnimation = {
        initial: { y: -100, x: 50 }, // Starting slightly above the visible area and centered
        animate: {
            y: [-200, 400], // Smaller vertical movement to be less intrusive
            x: [0, 60], // Less horizontal movement for a subtler sway
            opacity: [1, 0.5, 1], // Add slight opacity changes for a more dynamic effect
            transition: {
                duration: 2, // Shorter duration for a quicker fall
                ease: "linear", // For a constant falling speed
                repeat: Infinity, // Loop the animation forever
                repeatDelay: 2, // Short delay before repeating the animation for continuous effect
            },
        },
    };



    return (
        <>
            <div className='toparea'>
                <div className="spork-cnt">
                    <motion.div className="spork-slider blk ka" variants={textVariants} initial="initial" animate="animate" >
                        {Array.from({ length: 25 }).map((_, index) => (
                            <React.Fragment key={index} >
                                Spoon wif Fork ~ $SPoRK ~ ON SOLANA ~&nbsp;
                            </React.Fragment>
                        ))}
                    </motion.div>
                    <motion.img src={spork} alt="spork" className='spork' variants={bounceVariants}
                        animate="animate" />
                </div>

                <div className="hand-btn-cnt">
                    <a href="https://raydium.io/swap/?outputCurrency=3jbzY6z4ruH9kfzpuvD5918tb8E2KNTGAZPzfywfAT1u">
                        <div className="buysolbtn tf">
                            BUY $SPoRK ON SoL
                        </div>
                    </a>
                    <img src={hand} alt="hand" className='hand' />
                </div>

                <div className="left-charcs"
                >
                    <motion.img leftVariants variants={leftVariants}
                        animate="animate" src={cha1} alt="char" className='charl cha1' />
                    <motion.img leftVariants2 variants={leftVariants2}
                        animate="animate" src={cha2} alt="char" className='charl cha2' />
                </div>

                <div className="right-charcs"
                >
                    <motion.img variants={rightVariants}
                        animate="animate" src={cha3} alt="char" className='charr cha3' />
                    <motion.img src={cha4} variants={rightVariant2}
                        animate="animate" alt="char" className='charr cha4' />
                </div>
                <img src={circs} alt="circs" className='circs' />
            </div>


            <div className="pinkarea">

                <img src={abtspork} className="topleftcnt" />


                <img src={lspoon} alt="" className='lspoon' />
                <img src={rspoon} alt="" className='rspoon' />
                <img src={spoongroup} alt="" className='spoongroup' />

                <div className="spinner-wrapper">

                    <motion.img src={blackstar} alt="blackstar" className='blackstar' animate={{ rotate: 360 }}
                        transition={{
                            duration: 7, // Adjust the duration of one spin cycle (in seconds)
                            ease: "linear", // Use a linear animation curve for a smooth, consistent spin
                            repeat: Infinity, // Repeat the animation infinitely
                        }}
                    />

                </div>

                <img src={mtune} alt="mtune" className='mtunep1' />

                <div className="mid-cnt">
                    <div className="title ka white">$SPORK ~ A <span className="green" >SPOON</span> WIF A <span className="blue">FORK</span>
                    </div>

                    <div className="body-cnt">
                        <motion.img variants={bounceVariants} animate="animate" src={lefthand} alt="lefthand" className="lefthand" />
                        <motion.img variants={bounceVariants} animate="animate" src={righthand} alt="righthand" className="righthand" />

                        <div className="left-txt">
                            {isMobile ?
                                <>
                                    <div className="l1">


                                        <div className="t ka">now, that’s what you call $SPORK</div>
                                    </div>

                                    <div className="l2">

                                        <div className="t ra">Imagine the scene, centuries ago, when  ancient humans </div>
                                    </div>

                                    <div className="l3 ra">
                                        first thought, "Why choose between scooping and stabbing? Why not live on the edge and do both?" And thus, the spork was born, a symbol of excellence, a beacon of efficiency in a world torn between soup and salad.
                                        <br></br>
                                        <br></br>
                                        now some boring stats about the token ~ 69m total supply, lp burnt 0/0 taxes
                                    </div>
                                </>
                                :
                                <>
                                    <div className="l1">

                                        <div className="n tf">now,</div>
                                        <div className="t ka">that’s what you call</div>
                                    </div>

                                    <div className="l2">
                                        <div className="n tf">spork</div>
                                        <div className="t ra">Imagine the scene, centuries ago, when  ancient humans </div>
                                    </div>

                                    <div className="l3 ra">
                                        first thought, "Why choose between scooping and stabbing? Why not live on the edge and do both?" And thus, the spork was born, a symbol of excellence, a beacon of efficiency in a world torn between soup and salad.
                                        <br></br>
                                        <br></br>
                                        now some boring stats about the token ~ 69m total supply, lp burnt 0/0 taxes
                                    </div>
                                </>
                            }

                        </div>

                        <div className="right-txt">
                            <div className="right-slide tf" >
                                <motion.div className="text-slide" variants={upTextVariants} initial="initial" animate="animate" >

                                    {Array.from({ length: 500 }).map((_, index) => (
                                        <React.Fragment key={index} >
                                            <p>SPoRK</p>
                                        </React.Fragment>
                                    ))}
                                </motion.div>

                            </div>
                        </div>

                    </div>

                </div>


                <motion.div
                    className="photoslider"
                    variants={sliderVariants}
                    initial="initial"
                    animate="animate"
                >
                    {Array.from({ length: 40 }).map((_, index) => (
                        <React.Fragment key={index}>
                            <img src={photocollection} alt="photocollection" className='photocollection' />
                        </React.Fragment>
                    ))}
                </motion.div>

            </div>





            <div className="greyarea">

                <div className="brancharea">
                    <img src={branch} alt="" className='branch' />
                    {Array.from({ length: 3 }).map((_, index) => (
                        <motion.img
                            key={index}
                            src={leaf}
                            alt=""
                            className='leaf'
                            variants={leafAnimation}
                            initial="initial"
                            animate="animate"
                        />
                    ))}

                </div>

                <div className="flyspoonarea">
                    <motion.div className="spoon-cnt" animate={containerAnimation}>
                        <motion.img
                            src={wing}
                            alt="wing"
                            className='wing wing1'
                            animate={wingAnimation}
                        />
                        <img src={spork} alt="" className='sporkcen' />
                        <motion.img
                            src={wing}
                            alt="wing"
                            className='wing wing2'
                            animate={wingAnimation}
                        />
                    </motion.div>

                    <img src={apple} alt="" className='apple apple1' />
                    <img src={apple} alt="" className='apple apple2' />
                    <img src={apple} alt="" className='apple apple3' />
                </div>

                <motion.img src={moon} alt="" className='moon' variants={bounceVariants}
                    animate="animate" />

                <img src={mtune} alt="mtune" className='mtunep2' />
                <motion.img src={mtune} alt="mtune" className='mtunep3' variants={bounceVariants}
                    animate="animate" />



                <div className="textarea">
                    {isMobile ? <>
                        <img src={endtitlem} alt="" className='endtitle' />
                        <img src={endtitle2m} alt="" className='endtitle2' />
                    </>
                        : <>
                            <img src={endtitle} alt="" className='endtitle' />
                            <img src={endtitle2} alt="" className='endtitle2' />
                        </>}

                </div>


                <img src={waves} alt="" className='waves' />


                {/* 
                <div className="ocean-cnt">
                    <img src={wave3} alt="wave3" className='wave3 wave' />
                    <img src={wave2} alt="wave2" className='wave2 wave' />
                    <img src={wave1} alt="wave1" className='wave1 wave' />
                </div> */}

            </div>






            <motion.div
                className="spoonslider"
                variants={sliderVariants}
                initial="initial"
                animate="animate"
            >
                {Array.from({ length: 25 }).map((_, index) => (
                    <React.Fragment key={index}>
                        <img src={spooncollection} alt="photocollection" className='spooncollection' />
                    </React.Fragment>
                ))}
            </motion.div>



            <div className="footer">

                <img src={footer} alt="" className='footerbox' />

                <div className="item-cnt">
                    <div className="left">
                        <img src={logo} alt="logo" className='logo' />
                        <img src={spork} alt="" className='spork' />
                    </div>

                    <div className="right">
                        <div className="clouds-cnt">
                            <a href="https://twitter.com/SporkOnSol">
                                <img src={cloud1} alt="cloud1" className='cloud' />
                            </a>
                            <a href="https://t.me/spork_portal">
                                <img src={cloud2} alt="cloud1" className='cloud' />
                            </a>
                            <a href="https://dexscreener.com/solana/7xePKci69KtBedCYGPYa4XYWT8KpjX9JmstvDB9BE73P">
                                <img src={cloud3} alt="cloud1" className='cloud' />
                            </a>
                        </div>
                    </div>

                </div>


            </div>
        </>
    )
}

export default Toparea